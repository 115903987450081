import { createStore } from 'vuex'

export default createStore({
  state: {
    userInfo: null,
    infraUser: []
  },
  mutations: {
    setUserInfo: (state, data) => state.userInfo = (data.meResponse) ? data.meResponse : null
  },
  actions: {

  },
  modules: {

  }
})
