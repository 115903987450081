import { createRouter, createWebHashHistory } from 'vue-router'
import axios from 'axios'

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: () => import("../views/user/Dashboard.vue"),
    children:[
      {path: "", name: "Dashboard.datacenters", component: () => import("../views/user/Infras.vue")},
      {path: "vms", name: "Dashboard.machines", component: () => import("../views/user/Machines.vue")},
      {path: "shop", name: "Dashboard.shop", component: () => import("../views/user/Shop.vue")}
    ]
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("../views/admin/old/Admin.vue"),
    children: [
      {path: "", name: "Admin.groups", component: () => import("../views/admin/old/AdminGroups.vue")},
      {path: "users", name: "Admin.users", component: () => import("../views/admin/old/AdminUsers.vue")},
      {path: "settings", name: "Admin.settings", component: () => import("../views/admin/old/AdminSettings.vue")},
    ]
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: () => import("../views/user/maintenance.vue")
  },
  // Testing new UI admin
  {
    path: "/admin2",
    name: "Admin2",
    component: () => import("../views/admin/AdminDashboard.vue"),
    children: [
      {path: "", name: "Admin2.dashboard", component: () => import("../views/admin/Dashboard.vue")},
      {path: "users", name: "Admin2.users", component: () => import("../views/admin/Users.vue")},
      {path: "groups", name: "Admin2.groups", component: () => import("../views/admin/Groups.vue")},
      {path: "quotas", name: "Admin2.quotas", component: () => import("../views/admin/Quotas.vue")},
      {path: "templates", name: "Admin2.templates", component: () => import("../views/admin/Templates.vue")},
      {path: "infras", name: "Admin2.infras", component: () => import("../views/admin/Infrastructures.vue")},
      {path: "events", name: "Admin2.events", component: () => import("../views/admin/Events.vue")},
      {path: "settings", name: "Admin2.settings", component: () => import("../views/admin/Settings.vue")},
      {path: "about", name: "Admin2.about", component: () => import("../views/admin/About.vue")}
    ]
  },
  // Login route
  {
    path: "/login",
    name: "Login",
    beforeEnter: async function(to, from, next){
      try{
        await axios.get("/api/users/me");
        next("/");
      }
      catch(e){
        next();
      }
    },
    component: () => import("../views/user/Login.vue")
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/"
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

/**
 * Check if user is authenticated
 */
router.beforeEach( function(to, from, next){
  
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  if(to.path === "/login"){
    next();
  }
  else if(to.path === "/maintenance"){
    next();
  }
  else{
    axios.get("/api/users/me")
      .then( (answer) => {

        // Trying to access an admin page ?
        if( to.path.startsWith("/admin") && answer.data.meResponse.authLevel > 1 ) {
          next("/");
        } 
        else{
          next();
        }

      })
      .catch( (answer) => {

        if(answer.response){
          switch(answer.response.status){
            case 504:
              console.log("API DOWN");
              router.push("/maintenance");
              break;
            default:
              console.log(answer);
              next("/login");
              break;
          }
        }
        else{
          console.log(answer);
          next("/login");
        }
      });
  }
  
});

export default router
