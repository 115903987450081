<template>
  <router-view></router-view>
</template>

<script setup>

  import axios from 'axios';
  import { onMounted } from 'vue';
  import { useRouter } from 'vue-router';

  const router = useRouter();

  /**
   * Function to renew token if user is always on the app
   */
  function keepalive(){
    axios.get("/api/keepalive")
      .then( answer => {
        if(answer.data.newToken){
          document.cookie = `ci2n_token=${answer.data.newToken}; path=/;`;
          console.log("Token renewed : " + answer.data.message);
        }
        else{
          console.log("No need to renew token : " + answer.data.message);
        }
        setTimeout( () => keepalive() , 5 * 60000);
        // setTimeout( () => keepalive() , 10000);
      })
      .catch( e => {
        // document.location = "/";
        router.push("/");
      })
  }

  /**
   * Call when the app is mounted
   */
  onMounted( () => {
    let token = document.cookie.split(";")[0].split("=");
    if(token[0] === "ci2n_token"){ //--> Only if connected and token exists 
      // Call the keepalive function
      keepalive();
    }
  });

</script>


<style>
  body {
    background: url('/assets/img/FormaCloud.jpg') center/cover;background-color:#f8f9fa;
  }
</style>